/*This is styling for the header */

.header {
  position: relative;
  text-align: center;
  color: #fff; 
}

.header-image {
  position: relative;
  height: 80vh; 
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 75%; 
  transform: translateX(-50%); 
  width: 50%; 
  height: 100%;
  background-color: rgba(148, 60, 60, 0.75); 
}

.header-content {
  position: absolute;
  top: 50%;
  left: 75%; 
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 20px;
  width: 50%; 
  /* font-size: 2rem; */
}

.header-caption {
  padding-top: 5px;
  padding-bottom: 2rem;
}

.header-button-container {
    gap: 10px;
}

.learn-more-button {
  background-color: #007bff; 
  color: #fff; 
  padding: 10px 20px;
  font-size: 1.2rem; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.15s ease-in;
  margin: 0px 5px;
}

.learn-more-button:hover {
  background-color: #0056b3; 
}

.home-header-call {
  background-color: #ed4545; 
  color: #fff; 
  padding: 10px 20px;
  font-size: 1.2rem; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.15s ease-in;
}

.home-header-call:hover {
  background-color: #161616;
}

@media screen and (max-width: 390px) {
    .header-button-container {
        font-size: 0.5rem;
        width: 110%;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 400px) {
  .header-caption {
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 440px) {
  .main-header {
    font-size: 4vw;
  }
}

@media screen and (max-width: 480px) {
  .header-content {
    font-size: 0.8rem;
  }

  .learn-more-button {
    font-size: 1rem;
    padding: 8px 16px;
  }
}

@media screen and (max-width: 750px) {
  .header {
    display: flex;
    flex-direction: column;
  }

  .header-image {
    width: 100%;
    height: auto;
  }

  .learn-more-button {
    max-width: 40vw;
  }
}

@media screen and (max-width: 660px) {
  .header-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 5px;
  }

  .learn-more-button,
  a[href="tel:9452676237"] {
    width: 70%;
    font-size: 0.8rem;
  }
}

/* End of header styling */

.body-wrapper {
  margin-left: 3vw;
  margin-right: 3vw;
}

#red-heading {
  color: #ed4545;
}

/* Start of 'About' styling */

.about-home-header {
  font-weight: bolder;
  border-bottom: 1px solid #f3f0ed;
  margin-top: 3vh;
}

.why-us-wrapper {
  display: flex; /* Use flexbox to create a horizontal layout */
  align-items: center; /* Center vertically within the wrapper */
  justify-content: center; /* Center horizontally within the wrapper */
}

.why-us-image {
  flex: 1; /* Let the image take up 50% of the width */
  text-align: center; /* Center the image horizontally */
  padding: 2vh; /* Adjusted padding for better spacing */
}

.why-image {
  width: 40vw;
  height: 50vh;
  object-fit: cover;
  border-radius: 100px;
}

.why-us-text {
  flex: 1; /* Let the text take up 50% of the width */
  padding: 2vh; /* Adjusted padding for better spacing */
  text-align: left;
}

@media (max-width: 1110px) {
  .why-us-wrapper {
    display: grid;
  }

  .why-image {
    width: 80vw;
  }

  .what-why {
    text-align: center;
  }
}

@media (max-width: 520px) {
  .about-header {
    font-size: 3vh;
  }
}

/* End of 'About' styling */

/* Start of 'Services' styling */

/* Common styling for both large and small screens */
.services-header {
  border-bottom: 1px solid #f3f0ed;
}

.services-header-caption {
  margin-bottom: 2vh;
}

.highlight-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4vw;
}

.service-image {
  position: relative;
  max-width: 40vw;
  border-radius: 100px;
}

.list-of-services {
  text-align: left;
  list-style: none;
}

.list-of-services li {
  border: 1px solid #ed4545;
  border-radius: 100px;
  padding: 2vh 4vh; /* Adjusted padding for better touch experience */
  background-color: #ed4545;
  width: 30vw;
  font-size: 2vh; /* Changed from 2vw to 2vh */
  font-weight: bolder;
  margin-top: 1vh;
  display: flex; /* Added to make it a flex container */
  align-items: center; /* Vertically center align content */
  gap: 1rem; /* Adjust the gap between the icon and text */
}

.list-of-services li a.service-item {
  /* Add the CSS styles you want for the Link component here */
  text-decoration: none;
  color: inherit; /* Inherit color from the parent li */
  display: inherit;
  align-items: inherit;
  gap: inherit;
}

.service-item {
  display: flex;
  color: #f3f0ed;
}

.service-icon {
  font-size: 2rem;
}

.more-services-link {
  color: #f3f0ed;
  text-decoration: none;
  transition: 0.3s ease-in;
}

.more-services-link:hover {
  text-decoration: underline;
  color: #161616;
}

/* Media query for screens smaller than 1040px */
@media (max-width: 1110px) {
  .highlight-image-wrapper {
    flex-direction: column; /* Stack the image below the ul */
    align-items: center;
    text-align: center; /* Center-align the ul and the image */
  }

  .service-image {
    display: none;
    max-width: 100%; /* Make the image full width within the container */
    margin-top: 1vh; /* Add some space between the ul and the image */
    max-height: 50vh;
  }

  .list-of-services li {
    width: 90vw; /* Make the list items full width within the container */
    font-size: 3vw;
  }
}

/* End of 'Services' styling */

/* Start of 'Contact' styling */
.contact-wrapper {
  position: relative;
  padding-top: 2vw;
}

.firetruck-image {
  width: 80vw;
  height: 50vh;
  object-fit: cover;
  border-radius: 50px;
  border: 1px solid #ed4545;
}

.CTA {
  background: #161616;
  border: 1px solid #f3f0ed;
  border-radius: 10px;
  padding: 2vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 1;
  width: 70%;
  max-width: 400px;
}

.CTA h2 {
  padding-bottom: 2vh;
}

.home-contact-link {
  text-decoration: none;
  color: #f3f0ed;
  background: #ed4545;
  font-size: larger;
  font-weight: 600;
  border-radius: 10px;
  padding: 4px;
}

.home-contact-link:hover {
  text-decoration: underline;
  transition: 0.3s ease-in;
}

@media screen and (max-width: 767px) and (orientation: landscape) {
  .firetruck-image {
    width: 90vw; /* Increase the width for landscape mode */
    height: 80vh; /* Increase the height for landscape mode */
  }
}

/* Add additional styling as needed */

/* End of 'Contact' styling */
