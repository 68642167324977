/* Add this CSS to your Services.css file */

.services-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem; /* Increase padding for better spacing */
  min-height: 100vh;
}

/* Add this CSS to your Services.css file */

.service-text-image-container {
  display: flex;
  justify-content: space-between; /* Space items evenly */
  align-items: center; /* Vertically center items */
  text-align: left;
  gap: 14vw;
  margin: 2rem 5rem;
}

.services-list-container li {
  text-align: left;
}

.service-text-image-item {
  flex: 1; /* Make each item grow to fill available space */
  max-width: 100%; /* Initially, each item takes up half of the container */
  padding: 1rem; /* Add padding for spacing */
}

@media screen and (max-width: 1200px)
 {
  .service-text-image-container {
    margin: 2rem 2rem;
  }
 }
/* Adjust the layout for screens 800px and smaller */
@media screen and (max-width: 1000px) {

.service-text-image-container {
  flex-direction: column;
  gap: 5vw;
  margin: 2rem 2rem;
}
  .service-text-image-item {
    max-width: 100%; /* Each item takes up the full width on smaller screens */
    padding: 0; /* Remove padding to stack items without spacing */
  }

  .working-on-firetruck {
    max-width: 100%; /* Ensure the image fits within the container */
  }
}

@media screen and (max-width: 800px) {
  #service-page-header-text {
    font-size: 1rem;
  }
}





/* Style the introductory paragraph */
.services-wrapper p {
  font-size: 1.7rem;
  margin-bottom: 2rem; /* Add some bottom margin */
}

@media screen and (max-width: 800px) {
  .services-wrappr p {
    font-size: 0.5rem;
  }
}

/* Style the lists container */
.services-lists-container {
  display: flex;
  justify-content: space-around; /* Spread the lists evenly */
  width: 100%; /* Ensure lists take up full width */
  gap: 20px;
}

/* Style the individual list containers */
.services-list-container {
  flex: 1; /* Make each list container grow evenly */
  padding: 1.5rem; /* Add padding for spacing */
  background-color: #ed4545; /* Set a white background for lists */
  border-radius: 8px; /* Add rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  transition: transform 0.2s ease-in-out; /* Add a subtle hover effect */
}

.services-list-container:hover {
  transform: translateY(-10px); /* Lift the container on hover */
}

/* Style the images */
.working-on-firetruck {
  width: auto;
  min-height: 40vh;
  object-fit: contain;
  height: auto;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
  border-radius: 8px;
  max-height: 50vh;
}

.services-list-container ul {
  padding: 0;
  list-style: none;
}

.services-list-container li {
  list-style: inside;
}

.services-list-container ul li {
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  position: relative;
}

/* Add custom bullet points */
.services-list-container ul li::before {
  content: "\2022"; /* Bullet point character */
  color: #e74c3c; /* Custom bullet point color */
  font-weight: bold; /* Make the bullet point bold */
  display: inline-block;
  width: 1em; /* Adjust the width as needed */
  margin-left: -1em; /* Position the bullet point to the left */
  position: absolute;
  left: 0;
}

.services-lists-header {
  border-bottom: 1px solid #fff;
  margin-top: 1rem; /* Add top margin to headers */
}

@media screen and (max-width: 1040px) {
  .services-lists-container {
    flex-direction: column;
    width: 80%;
    text-align: left;
    margin-top: 10px;
  }

  .services-list-container:hover {
    transform: scale(1.05);
  }

  .services-list-container ul li {
    margin-left: 1.5em;
  }
}

.service-map-header {
  padding: 2rem 1rem;
}

.service-map-image {
  height: auto;
  object-fit: cover;
}

@media screen and (max-width: 1100px) {
  .service-map-image {
    width: 100%;
    height: 60vh;
    object-fit: cover;
  }
}

@media screen and (max-width: 600px) {
  .service-map-image {
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }
}

.map-zoom-container {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
  display: inline-block;
}

/* Default scale for screens larger than 600px */
.map-zoom-container.map-zoomed {
  transform: scale(1.5); /* Adjust the default scale factor as needed */
}

/* Scale for screens smaller than or equal to 600px */
@media screen and (max-width: 600px) {
  .map-zoom-container.map-zoomed {
    transform: scale(1.8); /* Adjust the scale factor for smaller screens */
  }
}

.service-map-image {
  height: auto;
  object-fit: cover;
}

.service-map-image.map-zoomed-image {
  cursor: zoom-out;
}


