* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
  background: #161616;
  color: #F3F0ED;
  font-family: 'Montserrat', sans-serif;
  font-size: calc(15px + 0.390625vw);
}

h1, h2, h3 {
  font-weight: 800;
}



