.contact-wrapper {
    text-align: center;
    padding: 20px;
    max-width: 100%;
}

.contact-page-header {
    border-bottom: 1px solid #fff;
}

h1 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.contact-info {
    margin-bottom: 30px;
}

.contact-info p {
    font-size: 1.2rem;
}

.contact-info ul {
    list-style: none;
    padding: 0;
}

.contact-info li {
    margin-bottom: 10px;
}

.contact-info a {
    color: #007bff;
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}

.contact-form {
    text-align: left;
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #161616;
}

.contact-form h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.contact-form textarea {
    width: 100%;
    max-width: 100%;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
