.about-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 0rem 6rem;
  min-height: 100vh;
}

.about-logo {
  max-width: 80vw;
  width: auto;
  max-height: 40vh;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.descriptive-text {
  text-indent: 1em;
}

.about-header {
  text-align: center;
  border-bottom: 1px solid #f3f0ed;
}

p {
  padding-bottom: 1rem;
}

@media screen and (max-width: 750px) {
  .about-wrapper {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.service-container {
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 2rem;
}

.service-description {
  max-width: 30%;
}

.service-map {
  max-width: 60%;
  height: auto;
  border-radius: 5%;
  transition: 0.3s ease-in;
}

.service-map-enlarged {
  transform: scale(1.5);
}

@media screen and (max-width: 1250px) {
  .service-container {
    flex-direction: column;
  }

  .service-map {
    /* min-height: 50vh; */
    max-width: 150%;
  }
}

@media screen and (max-width: 730px) {
  .service-image {
    min-height: 40vh;
  }
}

@media screen and (max-width: 500px) {
  .about-wrapper {
    margin: 0;
  }
}

.cert-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 5vh;
  }
  
  .cert-container img {
    height: 15vh;
    width: auto;
    margin: 0 10px;
  }

  @media screen and (max-width: 1200px) {
    .cert-container img {
      height: 10vh;
    }
  }
  
  /* Media query for screens less than 800px */
  @media screen and (max-width: 800px) {
    .cert-container img {
      height: 4vh; /* Reduce the height for smaller screens */
    }
  }
  
