.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .scroll-to-top:hover {
    background-color: #fff;
    color: #161616;
  }
  